<template>
  <div>
    <f-default-header :title="$t('pages.profile.title')" :subtitle="$t('pages.profile.subtitleSetPassword')" />

    <f-set-pass />

    <f-actions-btn-holder>
      <v-btn color="secondary" elevation="0" large @click="cancel">
        {{ $t('labels.btnCancel') }}
      </v-btn>
      &nbsp;
      <v-btn color="primary" elevation="0" large @click="save">
        {{ $t('labels.btnSave') }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FSetPass from "../../components/profile/SetPass.vue";

export default {
  components: {
    FSetPass,
  },
  methods: {
    cancel() {
      this.$router.push({
        name: "profile-view",
      });
    },
    async save() {
      const passData = this.$store.state.profile.passData;
      await this.$store.dispatch("auth/setPassword", {
        password: passData.password,
        rePass: passData.repass,
        id: this.$store.state.auth.user.id,
      });
      this.$store.commit("snackbar/show", {
        content: this.$t("snackbar.passwdAlterSuccess"),
        type: "success",
      });
      this.cancel();
    },
  },
};
</script>
