<template>
  <div>
    <f-default-header :title="$t('pages.profile.title')" />

    <f-data-form />
    <f-card-spacer />
    <f-reset-pass />

    <f-actions-btn-holder>
      <v-btn color="secondary" elevation="0" large @click="logout">
        {{ $t('labels.btnLogout') }}
      </v-btn>
      &nbsp;
      <v-btn color="primary" elevation="0" large @click="save">
        {{ $t('labels.btnEdit') }}
      </v-btn>
    </f-actions-btn-holder>
  </div>
</template>

<script>
import FDataForm from "../../components/profile/DataForm.vue";
import FResetPass from "../../components/profile/ResetPass.vue";

export default {
  components: {
    FDataForm,
    FResetPass,
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      await this.$store.dispatch("profile/show");
    },
    logout() {
      this.$store.dispatch("auth/logout");
      this.$router.push({
        name: "login",
      });
    },
    async save() {
      await this.$store.dispatch("profile/save");
      this.$store.commit("snackbar/show", {
        content: this.$t("snackbar.dataAlterSuccess"),
        type: "success",
      });
    },
  },
};
</script>
