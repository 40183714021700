<template>
	<div>
		<f-card>
			<v-row>
				<v-col cols="12">
					<f-card-title>{{ $t("pages.profile.personalData") }}</f-card-title>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12">
					<v-text-field
						outlined
						dense
						:label="$t('labels.formName')"
						v-model="user.name" />
					<v-text-field
						:disabled="true"
						outlined
						dense
						:label="$t('labels.formEmail')"
						v-model="user.email" />
					<v-btn-toggle v-model="user.lang">
						<v-btn @click="setLanguage('en')" value="en">{{ $t("en") }}</v-btn>
						<v-btn @click="setLanguage('es')" value="es">{{ $t("es") }}</v-btn>
						<v-btn @click="setLanguage('pt')" value="pt">{{ $t("pt") }}</v-btn>
					</v-btn-toggle>
				</v-col>
			</v-row>
		</f-card>
	</div>
</template>

<script>
export default {
	computed: {
		user() {
			return this.$store.state.profile.view;
		},
	},
	methods: {
		async setLanguage(lang) {
			localStorage.currentLang = lang;
			this.$i18n.locale = lang;
			this.$vuetify.lang.current = lang;

			await this.$store.dispatch("profile/save");
			this.$store.commit("snackbar/show", {
				content: this.$t("snackbar.dataAlterSuccess"),
				type: "success",
			});
		},
	},
};
</script>
