<template>
  <div>
    <f-card>
      <v-row>
        <v-col cols="12">
          <br />
          <v-text-field
            outlined
            dense
            :label="$t('labels.formNewPassword')"
            type="password"
            v-model="auth.password"
          />
          <v-text-field
            outlined
            dense
            :label="$t('labels.formConfirmPassword')"
            type="password"
            v-model="auth.repass"
          />
        </v-col>
      </v-row>
    </f-card>
  </div>
</template>

<script>
export default {
  computed: {
    auth() {
      return this.$store.state.profile.passData;
    },
  },
};
</script>
